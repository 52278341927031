import { RuntimeEnvironmentVariable } from './env';
import sbxFallback from './sbx-fallback';

const ENV: RuntimeEnvironmentVariable = {
  ...sbxFallback,

  REACT_APP_ACCOUNT_PROVISIONING_API_HOST: '',
  REACT_APP_ACCOUNT_VERIFICATION_SERVICE_API_HOST:
    'https://account-verification-omop.staging.ehrocks.com',
  REACT_APP_BING_MAPS_SERVICE_API_KEY: '',
  REACT_APP_CAREER_PAGE_HOST: '',
  REACT_APP_DISABLED_EMPLOYING_ENTITY_FOR_PAYROLL_FREEMIUM: '',
  REACT_APP_ENABLED_ATS_CREATE_JOB_NEW_UI: '',
  REACT_APP_ENABLED_GT_INTERNAL_NEW_HIRE: '',
  REACT_APP_ENABLED_KP_THEME: 'false',
  REACT_APP_ENABLED_REBRANDING: '',
  REACT_APP_FIREBASE_APIKEY: '',
  REACT_APP_FIREBASE_APP_ID: '',
  REACT_APP_FIREBASE_AUTH_DOMAIN: '',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '',
  REACT_APP_FIREBASE_PROJECT_ID: '',
  REACT_APP_FIREBASE_STORAGE_BUCKET: '',
  REACT_APP_INTERNAL_INTEGRATIONS_HOST: '',
  REACT_APP_KP_SYNC_SERVICE_HOST: 'https://sbx-omop-kss.staging.ehrocks.com',
  REACT_APP_LOCALE_CONFIG_HOST: '',
  REACT_APP_LOCALE_PROJECT_ID: '',
  REACT_APP_MAIN_APP_HOST: 'https://omop.staging.ehrocks.com',
  REACT_APP_MARKETPLACE_HOST:
    'https://sbx-omop-marketplace.staging.ehrocks.com',
  REACT_APP_MEETING_MANAGEMENT_SERVICE_API_HOST:
    'https://meeting-management.staging.ehrocks.com',
  REACT_APP_PAY_RUN_SERVICE_API_HOST:
    'https://sbx-omop-payrun.staging.ehrocks.com',
  REACT_APP_PAYROLL_API_HOST: 'https://payroll.omop.staging.ehrocks.com',
  REACT_APP_REACTIONS_HOST: '',
  REACT_APP_STARTING_GUIDE_PAGE_INSPECTLET: '',
  REACT_APP_STRIPE_PUBLIC_KEY_AU: '',
  REACT_APP_STRIPE_PUBLIC_KEY_GB: '',
  REACT_APP_SUPERFUND_LOOKUP_SERVICE_API_HOST:
    'https://api.staging.ehrocks.com/superfund',
  REACT_APP_SURVEY_HOST: '//api.staging.ehrocks.com/survey-api/api',
  REACT_APP_SURVEYS_WITH_ALLOCATIONS: 'true',
  REACT_APP_VITALLY_TOKEN: '',
  REACT_APP_WORKFLOW_ENGINE_API_HOST:
    'https://workflow-engine.staging.ehrocks.com',
};

export default ENV;

import { RuntimeEnvironmentVariable } from './env';
import sbxFallback from './sbx-fallback';

const ENV: RuntimeEnvironmentVariable = {
  ...sbxFallback,

  REACT_APP_ACCOUNT_PROVISIONING_API_HOST: '',
  REACT_APP_BING_MAPS_SERVICE_API_KEY: '',
  REACT_APP_CAREER_PAGE_HOST: '',
  REACT_APP_CONSENT_MARKETING_HOST:
    'https://consent-service.staging.ehrocks.com',
  REACT_APP_DISABLED_EMPLOYING_ENTITY_FOR_PAYROLL_FREEMIUM: '',
  REACT_APP_EBF_BFF_MOBILE_HOST:
    'https://ebf-bff-mobile.staging-ehfintech.com/secure/query',
  REACT_APP_EBF_EH_FINTECH_HOST: 'https://api.staging-ehfintech.com',
  REACT_APP_ENABLED_ATS_CREATE_JOB_NEW_UI: '',
  REACT_APP_ENABLED_GT_INTERNAL_NEW_HIRE: '',
  REACT_APP_ENABLED_KP_THEME: 'false',
  REACT_APP_ENABLED_REBRANDING: '',
  REACT_APP_FIREBASE_APIKEY: '',
  REACT_APP_FIREBASE_APP_ID: '',
  REACT_APP_FIREBASE_AUTH_DOMAIN: '',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '',
  REACT_APP_FIREBASE_PROJECT_ID: '',
  REACT_APP_FIREBASE_STORAGE_BUCKET: '',
  REACT_APP_FORM_HOST: '',
  REACT_APP_INTERNAL_INTEGRATIONS_HOST: '',
  REACT_APP_KP_SYNC_SERVICE_HOST: 'https://sbx-omop-kss.staging.ehrocks.com',
  REACT_APP_LOCALE_CONFIG_HOST: '',
  REACT_APP_LOCALE_PROJECT_ID: '',
  REACT_APP_MAIN_APP_HOST: 'https://instapay.staging.ehrocks.com',
  REACT_APP_MEETING_MANAGEMENT_SERVICE_API_HOST:
    'https://meeting-management.staging.ehrocks.com',
  REACT_APP_PAY_RUN_SERVICE_API_HOST:
    'https://sbx-omop-payrun.staging.ehrocks.com',
  REACT_APP_PERFORMANCE_EVALUATION_SERVICE_API_HOST: '',
  REACT_APP_REACTIONS_HOST: '',
  REACT_APP_STARTING_GUIDE_PAGE_INSPECTLET: '',
  REACT_APP_STRIPE_PUBLIC_KEY_AU:
    'pk_test_51Mxiv3LvmZxRDXTxrsJaDZCWGLy7D8geXvV3WY0TblMKKe60z29LkeU99FJ38bh35yMT4Okkq5a8fl4icb1scgnU00BnQOciEA',
  REACT_APP_STRIPE_PUBLIC_KEY_GB:
    'pk_test_51NN3G7GOk6eRCqhcIiXpemqcTBNjdppkfUo5kcbpzHX6ug5WICSsRpNpOfQJU6NEJnwaz5cHueLkojIkds57H9Ob00OvoVCI39',
  REACT_APP_SUPERFUND_LOOKUP_SERVICE_API_HOST:
    'https://api.staging.ehrocks.com/superfund',
  REACT_APP_SURVEY_HOST: '//api.staging.ehrocks.com/survey-api/api',
  REACT_APP_SURVEYS_WITH_ALLOCATIONS: 'true',
  REACT_APP_VITALLY_TOKEN: '',
  REACT_APP_WORKFLOW_WIZARD_API_HOST: '',
  REACT_APP_AWARE_ENCRYPTED_KEY_ID: 'employmenthero',
  REACT_APP_AWARE_PASSWORD_PUBLIC_KEY:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwvMUK/L8P+SivrK8ZRPa21pPrmjyFf1otdFJMc1oEi/FoiSEWTyEHRMRhyHgJX4w+8wA4kxlfR8XVgGy3GqzcvV4MshPRXi2o5lM/YiENQJ9GNsdkGHkVlzCi0DMj8O4Vvvw3unRqIeebMvb7ID/gTXLcem2BhYCL5a6ac0kKrEPweAdQdjKG2BU4wHNNen7LKxXCWVdDbvtm8yM56BjnL8uqAfD4nRAQuFLapD6/mDwXJFn7ZobIvF7F2g5hSfCiv2ay1OSOdS7/XPjFzQQWxcJiXUR4OP+OTJGpxFNsdHylE1XEgRWbBLEQ60ngLw4wdU9qh5kARRVeiRgY8na9QIDAQAB',
};

export default ENV;

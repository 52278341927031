import { RuntimeEnvironmentVariable } from './env';
import sbxFallback from './sbx-fallback';

const ENV: RuntimeEnvironmentVariable = {
  ...sbxFallback,
  REACT_APP_ACCOUNT_VERIFICATION_SERVICE_API_HOST:
    'https://account-verification-omop.staging.ehrocks.com',
  REACT_APP_ENABLED_KP_THEME: 'false',
  REACT_APP_KP_SYNC_SERVICE_HOST: 'https://sbx-sushi-kss.staging.ehrocks.com',
  REACT_APP_MAIN_APP_HOST: 'https://sushi.staging.ehrocks.com',
  REACT_APP_MARKETPLACE_HOST:
    'https://sbx-omop-marketplace.staging.ehrocks.com',
  REACT_APP_PAY_RUN_SERVICE_API_HOST:
    'https://sbx-omop-payrun.staging.ehrocks.com',
  REACT_APP_PAYROLL_API_HOST: 'https://payroll.omop.staging.ehrocks.com',
  REACT_APP_WORKFLOW_ENGINE_API_HOST:
    'https://workflow-engine.staging.ehrocks.com',
};

export default ENV;

import { RuntimeEnvironmentVariable } from './env';
import sbxFallback from './sbx-fallback';

const ENV: RuntimeEnvironmentVariable = {
  ...sbxFallback,

  REACT_APP_MEETING_MANAGEMENT_SERVICE_API_HOST:
    'https://meeting-management.staging.ehrocks.com',
  REACT_APP_PAYROLL_API_HOST: 'https://test3.keypay.dev',
  REACT_APP_REPORT_SERVICE_API_HOST: 'https://reports.staging.ehrocks.com',
  REACT_APP_STARTING_GUIDE_PAGE_INSPECTLET: '',
  REACT_APP_STRIPE_PUBLIC_KEY_AU:
    'pk_test_51Mxiv3LvmZxRDXTxrsJaDZCWGLy7D8geXvV3WY0TblMKKe60z29LkeU99FJ38bh35yMT4Okkq5a8fl4icb1scgnU00BnQOciEA',
  REACT_APP_STRIPE_PUBLIC_KEY_GB:
    'pk_test_51NN3G7GOk6eRCqhcIiXpemqcTBNjdppkfUo5kcbpzHX6ug5WICSsRpNpOfQJU6NEJnwaz5cHueLkojIkds57H9Ob00OvoVCI39',
  REACT_APP_SUPERFUND_LOOKUP_SERVICE_API_HOST:
    'https://api.staging.ehrocks.com/superfund',
  REACT_APP_SURVEY_HOST: '//api.staging.ehrocks.com/survey-api/api',
  REACT_APP_SURVEYS_WITH_ALLOCATIONS: 'true',
  REACT_APP_PAYROLL_AU_PRIVATE_API_HOST: 'https://test3.keypay.dev',
  REACT_APP_PAYROLL_NZ_PRIVATE_API_HOST: 'https://test3.keypay.dev',
  REACT_APP_PAYROLL_UK_PRIVATE_API_HOST: 'https://test3.keypay.dev',
  REACT_APP_PAYROLL_MY_PRIVATE_API_HOST: 'https://test3.keypay.dev',
  REACT_APP_PAYROLL_SG_PRIVATE_API_HOST: 'https://test3.keypay.dev',
};

export default ENV;

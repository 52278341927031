import { RuntimeEnvironmentVariable } from './env';
import sbxFallback from './sbx-fallback';

const ENV: RuntimeEnvironmentVariable = {
  ...sbxFallback,

  REACT_APP_ACCOUNT_PROVISIONING_API_HOST: '',
  REACT_APP_BING_MAPS_SERVICE_API_KEY: '',
  REACT_APP_CAREER_PAGE_HOST: '',
  REACT_APP_ENABLED_ATS_CREATE_JOB_NEW_UI: '',
  REACT_APP_ENABLED_GT_INTERNAL_NEW_HIRE: '',
  REACT_APP_ENABLED_LMS_FEATURES: 'true',
  REACT_APP_ENABLED_ONE_ON_ONE_FEATURES: 'true',
  REACT_APP_FIREBASE_APIKEY: '',
  REACT_APP_FIREBASE_APP_ID: '',
  REACT_APP_FIREBASE_AUTH_DOMAIN: '',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '',
  REACT_APP_FIREBASE_PROJECT_ID: '',
  REACT_APP_FIREBASE_STORAGE_BUCKET: '',
  REACT_APP_JOB_LISTING_SUSHI_TRAIN_ENABLED: 'true',
  REACT_APP_LEARNING_REFRESH_TOKEN_REVOKED_NOTICE_ENABLED: 'true',
  REACT_APP_MEETING_MANAGEMENT_SERVICE_API_HOST:
    'https://meeting-management.staging.ehrocks.com',
  REACT_APP_PAYROLL_REPORT_SERVICE_API_HOST: '',
  REACT_APP_REACTIONS_HOST: '',
  REACT_APP_SENTRY_CONFIG:
    'https://380b30e57f2c4197a820be2e3f6d75d2@sentry.staging.ehrocks.com/69',
  REACT_APP_STARTING_GUIDE_PAGE_INSPECTLET: '',
  REACT_APP_STRIPE_PUBLIC_KEY_AU: '',
  REACT_APP_STRIPE_PUBLIC_KEY_GB: '',
  REACT_APP_SUPERFUND_LOOKUP_SERVICE_API_HOST:
    'https://api.staging.ehrocks.com/superfund',
  REACT_APP_SURVEY_HOST: '//api.staging.ehrocks.com/survey-api/api',
  REACT_APP_SURVEYS_WITH_ALLOCATIONS: 'true',
  REACT_APP_VITALLY_TOKEN: '',
  REACT_APP_WORKFLOW_WIZARD_API_HOST: '',
};

export default ENV;

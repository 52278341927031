/* eslint-disable immutable/no-mutation */
// XXX: Fix error on menu navigation
// Check khttps://github.com/Thinkei/frontend-core/pull/32574#discussion_r1733929980 again
// if you want to remove this polyfill
import 'regenerator-runtime/runtime';
// End of XXX

window.global = window.global || window;
window.module = window.module || window;

export {};
